import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Metcon Week`}</em></p>
    <p>{`2 Lap Run`}</p>
    <p>{`100Ft Single Arm Overhead KB Walking Lunge (53/35)`}</p>
    <p>{`500M Row`}</p>
    <p>{`100Ft Bear Crawl`}</p>
    <p>{`1 Mile Assault Bike`}</p>
    <p>{`100Ft Burpee Broad Jump`}</p>
    <p>{`1 Lap Run`}</p>
    <p>{`100Ft Single Arm Overhead KB Walking Lunge`}</p>
    <p>{`250M Row`}</p>
    <p>{`100Ft Bear Crawl`}</p>
    <p>{`1/2 Mile Assault Bike`}</p>
    <p>{`100Ft Burpee Broad Jump`}</p>
    <p>{`*`}{`Cash Out: 20 Back Squats (185/125)`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Sunday, August 5th we are having our Games Party at the
Barnett’s house.  All members and their families are invited!  We’ll
watch the final day of the CrossFit Games, eat, swim, and we’ll even
have a few chances to workout (with prizes for 1st place in each wod).
 Call, text or email Daniel for the address.  Family friendly so bring
the kids!  The gym will be closed for our party.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Get your 4 person (2 men/2 women) teams together now for True Grit
6!  RX and Scaled teams will battle it out for the 6th year at the Ville
for cash prizes and other goodies.  September 15th.  Register by
September 1st to ensure you get your t shirt on the day of the
competition.  If you’d like to compete but don’t have a team we have a
signup sheet by the white board.  If you’d like to help judge please add
your name to the sheet.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong"><a parentName="em" {...{
            "href": "https://www.eventbrite.com/e/true-grit-6-tickets-48356197689"
          }}>{`https://www.eventbrite.com/e/true-grit-6-tickets-48356197689`}</a></em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`For more info go to: truegritcomp.wordpress.com`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      